<template>
  <div>
    <div class="flex-start-center flex-wrap mt-20">
      <div class="cell-box mr-40">收件人姓名：<span class="font-weight-bold color-black">{{ orderLogisticsVo.consigneeRealname }}</span></div>
      <div class="cell-box mr-40">收件人电话：<span class="font-weight-bold color-black">{{ orderLogisticsVo.consigneeTelphone }}</span></div>
      <div class="cell-box mr-40">详细地址：<span class="font-weight-bold color-black">{{ orderLogisticsVo.consigneeAddress }}</span></div>
      <div class="cell-box mr-40">物流公司：<span class="font-weight-bold color-black">{{ orderLogisticsVo.logisticsName }}</span></div>
      <div class="cell-box mr-40">
        物流单号：
        <span  @click="untils.copy_text(orderLogisticsVo.expressNo)" class="font-weight-bold cur-pot color-blue">{{ orderLogisticsVo.expressNo }}</span>
      </div>
    </div>
    <div class="flex-start-center flex-wrap mt-20" v-if="orderReturnVo && orderReturnVo.gradeFile">
      <div class="cell-box flex-start-center flex-wrap">
        评级图片：
        <div class="w-50 h-50 flex-center-center" v-for="(img, index) in orderReturnVo.gradeFile" :key="index">
          <img class="w-100-w h-100-h" :src="img" alt="" @click="handleShowBigImg(orderReturnVo.gradeFile, index)">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _orderDetailMixin from "@/views/cmsPage/dqPlatform/orderListAboutManage/_mixins/_orderDetailMixin"
import untils from "@/untils"

export default {
  props: ['orderLogisticsVo', 'orderReturnVo'],
  mixins: [_orderDetailMixin],
  data() {
    return {
      untils,
    }
  }
}
</script>