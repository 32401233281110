import {
    getOrderDetailInfoApi,
    getServiceNoteListApi
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"

export default {
    data() {
        return {

        }
    },
    methods: {
        /** 展示大图 */
        handleShowBigImg(urls, index) {
            const tempUrls = urls.map(el => ({img_url: el}))
            if (urls && urls.length > 0) {
                this.$previewImg({
                    list: tempUrls,
                    current: index,
                    baseImgField: "img_url",
                    showMute: false,
                });
            }
        },
        /** 获取详情数据 */
        async getOrderDetailInfo() {
            this.$loading.show()
            const res = await getOrderDetailInfoApi({id: this.orderId})
            this.$loading.hide()
            if (res.status !== '200') return;
            this.orderProductInfoVo = res.data.orderProductInfoVo || {}
            this.productSnapshot = res.data.productSnapshot || {}
            this.orderLogisticsVo = res.data.orderLogisticsVo || {}
            this.orderReturnVo = res.data.orderReturnVo || {}
            this.gradeLogisticsVo = res.data.gradeLogisticsVo
            this.ratingInfoVo = res.data.ratingInfoVo
            this.orderLogVo = res.data.orderLogVo
            this.showPopup = true
        },
        /** 获取备注列表 */
        async getServiceNoteList() {
            const res = await getServiceNoteListApi({orderId: this.orderId})
            if (res.status !== '200') return
            this.orderNoteList = res.data
        }
    }
}