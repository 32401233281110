var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "base-count-down-com-page" }, [
    _c("span", { staticClass: "font-weight-bold color-red" }, [
      _vm._v(_vm._s(_vm.days))
    ]),
    _vm._v(" 天 "),
    _c("span", { staticClass: "font-weight-bold color-red" }, [
      _vm._v(_vm._s(_vm.hours))
    ]),
    _vm._v(" 时 "),
    _c("span", { staticClass: "font-weight-bold color-red" }, [
      _vm._v(_vm._s(_vm.minutes))
    ]),
    _vm._v(" 分 "),
    _c("span", { staticClass: "font-weight-bold color-red" }, [
      _vm._v(_vm._s(_vm.seconds))
    ]),
    _vm._v(" 秒 ")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }