<template>
  <span class="base-count-down-com-page">
    <span class="font-weight-bold color-red">{{ days }}</span> 天
    <span class="font-weight-bold color-red">{{ hours }}</span> 时
    <span class="font-weight-bold color-red">{{ minutes }}</span> 分
    <span class="font-weight-bold color-red">{{ seconds }}</span> 秒
  </span>
</template>

<script>
export default {
  props: {
    remainingTime: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 10,
      timeDifference: this.remainingTime,
      timer: null
    };
  },
  mounted() {
    this.updateCountdown();
    this.timer = setInterval(() => {
      if (this.timeDifference <= 0) {
        this.days = 0
        this.hours = 0
        this.minutes = 0
        this.seconds = 0
        return
      }
      this.updateCountdown();
    }, 1000);
  },
  methods: {
    updateCountdown() {
      this.timeDifference -= 1000
      this.days = Math.floor(this.timeDifference / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((this.timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((this.timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((this.timeDifference % (1000 * 60)) / 1000);
      this.$forceUpdate()
    },
    /** 删除倒计时 */
    clearTimer() {
      clearInterval(this.timer);
    }
  }
};
</script>
<style lang="scss" scoped>
.base-count-down-com-page {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>