<template>
  <div class="order-log-list-info-pages">
    <div class="order-log-item" v-for="(item, index) of orderLogVo" :key="index">
      <div class="order-item-left">{{ index + 1 }}</div>
      <div class="order-item-right">
        <div class="font-weight-bold color-gray">{{item.createTime}}</div>
        <div class="font-weight-bold color-black mt-5">{{item.userName}}</div>
        <div class="flex-start-center flex-wrap mt-5">
          <div v-if="false">{{ actionTypeMapText[item.type] || '备注' }}：</div>
          <div class="font-weight-bold color-black w-400">{{ item.content || item.statusMessage }}</div>
          <div class="font-weight-bold color-red w-400" v-if="item.remark">主动退款备注：{{ item.remark }}</div>
        </div>
        <div class="mt-5 flex-start-center">
          <template v-if="item.files">
            <div class="w-50 h-50 flex-center-center" v-for="(img, index) in item.files" :key="index">
              <img class="w-100-w h-100-h" :src="img" alt="" @click="handleShowBigImg(item.files, index)">
            </div>
          </template>
          <a-button class="ml-" v-if="item.videoUrl" @click="handleGoToShowVideo(item.videoUrl)">查看视频</a-button>
        </div>
        <div class="mt-5">
          <div class="font-weight-bold color-black" v-if="item.proofDesc">{{ item.proofDesc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _returnOrderDetailMixin from "@/views/cmsPage/dqPlatform/orderListAboutManage/_mixins/_returnOrderDetailMixin"

export default {
  mixins: [_returnOrderDetailMixin],
  props: ['orderLogVo'],
  data() {
    return {
      actionTypeMapText: {
        1: '买家操作',
        2: '卖家操作',
        3: '平台操作'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.order-log-list-info-pages {
  width: 100%;
  .order-log-item {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .order-item-left {
      margin-right: 20px;
      width: 30px !important;
      height: 30px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      background-color: #32788A;
    }
    .order-item-right {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
</style>